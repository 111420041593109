<template>
  <ion-page>
    <Header title="News" />
    <ion-content :fullscreen="true">
      <div class="container">
        <ul class="list">
          <li class="item" v-for="n in news" :key="n.id" @click="$router.push({name: 'PostDetail', params: {slug: n.slug}})">
            <div class="date text-sm">{{ n.created_at }}</div>
            {{ n.title }}
          </li>
        </ul>
      </div>
      <ion-infinite-scroll
        @ionInfinite="getNews"
        threshold="100px"
        id="infinite-scroll"
        :disabled="isDisabled"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          loading-text="Loading more data...">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue'
import { defineComponent } from 'vue'
import Header from '@/components/headerPage'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    Header,
    IonInfiniteScroll, IonInfiniteScrollContent
  },
  mounted () {
    this.getNews()
  },
  data () {
    return {
      news: [],
      page: 1,
      isDisabled: false
    }
  },
  methods: {
    async getNews(e) {
      let data = await this.$api.get('/api/post', {
          params: {page: this.page }
        }).then(res => res)

      if(data.meta.last_page == this.page) {
        this.isDisabled = true;
      }

      data.data.forEach(c => {
        this.news.push(c)
      })
      if(e && e.type == 'ion-infinite')
        e.target.complete();

      this.page++
    },

  }
})
</script>

<style>

</style>
