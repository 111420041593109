<template>
<ion-header mode="md" class="ion-no-border">
  <ion-toolbar>
    <ion-buttons>
      <ion-button @click="backTo? $router.push(backTo): $router.back()">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 12H5" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 19L5 12L12 5" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </ion-button>
      <ion-title>{{ title }}</ion-title>
      <ion-button v-if="right" @click="$router.push(right)">
        <slot name="icon-right"></slot>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
</template>

<script>
import { IonHeader, IonToolbar, IonTitle, IonButtons, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';

export default defineComponent({
  components: {
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton
  },
  props: {
    title: String,
    backTo: String,
    right: String
  }
})
</script>

<style>

</style>
